import logo from './logo.svg';
import './App.sass';
import { Route, Routes } from "react-router-dom";
import { Home } from "./componenets/Home";
import { Impress } from "./componenets/Impress";
import { Privacy } from "./componenets/Privacy";

function App() {
  return (
    <div className="App w-full h-screen">

      <Routes>
        <Route path="/">
          <Route index element={ <Home /> }></Route>
          <Route path="impress" element={ <Impress /> }></Route>
          <Route path="privacy" element={ <Privacy /> }></Route>
          <Route path="*" element={<Home/>}></Route>
        </Route>
      </Routes>



    </div>
  );
}

export default App;
