import { Fragment } from "react"
import { Link } from "react-router-dom"



export const Home = () => {
  return (
    <Fragment>
      <div className="w-full h-screen screen-1">
        <div className="inner w-full h-full flex items-center justify-center">
          <svg width="175" height="175" viewBox="0 0 175 175" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[100px] h-[100px]">
          <path d="M87.5 0C39.18 0 0 39.18 0 87.5C0 113.83 11.63 137.44 30.04 153.48C30.03 153.19 30 152.91 30 152.63V87.73C30 56.02 55.8 30.22 87.51 30.22C119.22 30.22 145.02 56.02 145.02 87.73C145.02 106.86 135.55 124.68 119.68 135.4C110.52 141.58 98.09 139.18 91.91 130.02C85.73 120.87 88.13 108.44 97.29 102.25C102.13 98.98 105.02 93.55 105.02 87.72C105.02 78.07 97.17 70.21 87.51 70.21C77.85 70.21 70 78.06 70 87.72V152.62C70 160.32 65.65 166.99 59.27 170.33C68.13 173.35 77.62 174.99 87.5 174.99C135.82 174.99 175 135.81 175 87.49C175 39.17 135.82 0 87.5 0Z" fill="white"/>
          </svg>
        </div>
      </div>

      <div className="w-full h-screen screen p-10">
        <div className="inner w-full h-full flex items-center justify-center">
          <div className="text-xl">
            coming...
         </div> 
        </div>
      </div>

      <div className="">
        <Link className="py-2 px-2" to={'impress'}>Impress</Link>
        <Link className="py-2 px-2" to={'privacy'}>Privacy</Link>
      </div>

    </Fragment>


  )
}     