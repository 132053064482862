import { Fragment } from "react"
import { Link } from "react-router-dom"



export const Impress = () => {
  return (
    <Fragment>
      <div className="w-full h-screen screen p-10">
        <Link to="/" className="absolute top-0 py-10">{'<'} Start</Link>
        <div className="inner w-full h-full flex items-center justify-center">
          

          <div className="text-xl">
         <span className="font-bold">studio.plural</span> medien produktion<br></br>
         Sanderstr 26<br></br>
         12047 Berlin<br></br>
         </div> 
        </div>
      </div>
    </Fragment>


  )
}     